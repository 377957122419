// import Vue from 'vue'
// /**
//  * 检查路径
//  * @param {string} path
//  * @returns {Boolean}
//  */
// Vue.prototype.szdTLValidExternal = function(path) {
// 	return /^(https?:|mailto:|tel:)/.test(path)

import { ElMessage } from "element-plus";

// }
export const szdTLValidExternal = function (path) {
  return /^(https?:|mailto:|tel:)/.test(path);
};

/**
 * 检查是否是空, 带消息提示
 */
export const szdTLIsNullMsg = function (str, msg) {
  if (str === undefined || str === null || str === "") {
    if (msg != undefined && msg != "") {
      ElMessage.error(msg);
    }
    return true;
  } else {
    return false;
  }
};
/**
 * 检查是否是空
 */
export const szdTLIsNull = function (str) {
  if (str === undefined || str === null || str === "") {
    return true;
  } else {
    return false;
  }
};

function szdTLCheck(str, type) {
  const regs = {
    // 检查url
    url: /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/,
    // 检查小写
    lower: /^[a-z]+$/,
    // 检查大写
    upper: /^[A-Z]+$/,
    // 检查是否是整数
    intNum: /^[0-9]+$/,
    // 检查是否是小数
    floatNum: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g,
    // 检查是否是字母
    alp: /^[A-Za-z]+$/,
    // 检查是否是合法手机号
    mobile: /^1[3-9]\d{9}$/,
    // 检查是否是合法电话号
    telephone: /^([0-9]{3,4}-)?[0-9]{7,8}$/,
    // 检查是否是合法的统一社会信用代码
    entCode: /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/,
    // 检查是否是合法email地址
    email:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  };

  const reg = regs[type];

  return reg.test(str);
}

export const szdCheck = szdTLCheck;

export const szdTLCheckNum = function (str) {
  if (str === undefined || str === null || str == 0) {
    return true;
  } else {
    return false;
  }
};
export const szdTLCheckNumMsg = function (str, msg) {
  if (str === undefined || str === null || str == 0) {
    if (msg != undefined && msg != "") {
      this.$message.error(msg);
    }
    return true;
  } else {
    return false;
  }
};
