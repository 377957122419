import router from "@/router";
import store from "~store";
import { createVNode, render } from "vue";
import { ElMessage } from "element-plus";
import { szdIfrDg } from "../../components/ifrDg/ifrDg.js";
import qs from "qs";

/**
 * 获取路由代码
 */
function getRouterCode() {
  const currentRoute = router.currentRoute.value;
  let rCode = currentRoute.name;
  if (["micro", "dest"].includes(currentRoute.name) && currentRoute.params.chapters && currentRoute.params.chapters.length > 0) {
    rCode = currentRoute.params.chapters[0];
  }
  return rCode ? rCode.toUpperCase() : "";
}

/**
 * 获取路由对象
 * @param {String} routerCode 路由代码
 */
function getRouterObj(routerCode) {
  if (routerCode) {
    routerCode = routerCode.toUpperCase();
    const routerObj = store.state.loginUser.routesObj[routerCode];
    if (routerObj) {
      return routerObj;
    } else {
      ElMessage.error("路由代码不存在或无权限" + routerCode);
    }
  } else {
    ElMessage.error("路由代码不能为空");
  }
  return null;
}

/**
 * 路由跳转或弹窗页面
 * @param {String} routerCode 路由代码
 * @param {Object} params 地址参数
 * @param {Object} mode 导航模式 prt 打印  dialog 弹窗打开
 */
function goNav(routerCode, params = {}, mode = { prt: false, dialog: false }) {
  if (!routerCode) {
    ElMessage.error("路由代码不能为空");
    return;
  }
  routerCode = routerCode.toUpperCase();
  //检查和获取路由对象
  const routerObj = getRouterObj(routerCode);
  if (!routerObj) return;
  //检查路由信息
  if (!routerObj.path) {
    ElMessage.error("路由地址不存在");
    return;
  }
  //跳转打印路由
  if (mode.prt) {
    if (routerObj.routerCodePrt) {
      return goNav(routerObj.routerCodePrt, params, { prt: false });
    } else {
      ElMessage.error("本功能不支持打印");
      return;
    }
  }

  //4.弹窗打开
  if (routerObj.isFrame === "4" || mode.dialog) {
    szdIfrDg(routerCode, params);
    return;
  }

  //打开应用
  if (routerObj.isBlank) {
    const fullPath = window.__POWERED_BY_QIANKUN__ ? "/micro" + getFullPath(routerObj.path, params) : getFullPath(routerObj.path, params); //全路径
    switch (routerObj.isFrame) {
      case "3": //新标签(无框架，路由唯一)
        window.open(fullPath, fullPath);
        break;
      case "9": //新标签(外链)
        const url = routerObj.destCode == null || routerObj.destCode === "" ? routerObj.routerCode : routerObj.compUrl;
        window.open(url, "_blank");
        break;
      default:
        window.open(fullPath, "_blank");
        break;
    }
  } else {
    if (routerObj.path.includes("/:")) {
      router.push({ path: routerObj.path, query: params });
    } else {
      router.push({ path: routerObj.path, query: params });
    }
  }
}

/**
 * 跳转弹窗页面
 * @param {String} routerCode 路由代码
 * @param {Object} params 地址参数
 * @param {Object} mode 导航模式 prt 打印
 */
function goNavDg(routerCode, params = {}, mode = { prt: false }) {
  if (mode.prt) {
    const routerObj = getRouterObj(routerCode); //检查和获取路由对象
    if (!routerObj) return null;
    //跳转打印路由
    if (routerObj.routerCodePrt) {
      szdIfrDg(routerObj.routerCodePrt, params);
    } else {
      ElMessage.error("本功能不支持打印");
    }
  } else {
    szdIfrDg(routerCode, params);
  }
}

/**
 * 获取路由的全路径
 * @param {String} routerCode 路由代码
 * @param {Object} params 地址参数
 */
function goNavPath(routerCode, params = {}) {
  if (!routerCode) {
    ElMessage.error("路由代码不能为空");
    return;
  }
  routerCode = routerCode.toUpperCase();
  //检查和获取路由对象
  const routerObj = getRouterObj(routerCode);
  if (!routerObj) return null;
  //检查路由信息
  if (!routerObj.path) {
    ElMessage.error("路由地址不存在");
    return null;
  }
  //跳转路全路径
  return getFullPath(routerObj.path, params);
}

/**
 * 参数转换为Url全路径
 * @param {String} path 路径
 * @param {Object} params 地址参数
 */
function getFullPath(path, params) {
  let fullPath = path;
  if (Object.keys(params).length === 0) return fullPath;

  if (fullPath.includes("/:")) {
    const parArr = fullPath.split("/").filter(item => item.startsWith(":"));
    parArr.forEach(item => {
      const key = item.replace(":", "");
      fullPath = fullPath.replace(`:${key}`, encodeURIComponent(params[key]));
    });
  } else {
    const query = setUrlParams(params);
    if (query) {
      fullPath = fullPath + "?" + query;
    }
  }
  return fullPath;
}

/**
 * 解析Url参数
 */
function getUrlParams(query) {
  return qs.parse(query);
}

/**
 * 转换Url参数
 */
function setUrlParams(params) {
  return qs.stringify(params);
}

/**
 * 关闭当前页签
 */
function closeFrameTag() {
  let visitedViews = store.state.frameTag.visitedViews;
  for (let i = 0; i < visitedViews.length; i++) {
    if (router.currentRoute.value.path === visitedViews[i].path) {
      store.dispatch("frameTag/delView", visitedViews[i]).then(({ visitedViews }) => {
        const latestView = visitedViews.slice(-1)[0];
        if (latestView) {
          router.push(latestView.fullPath);
        } else {
          router.push("/");
        }
      });
      break;
    }
  }
}

/**
 * 动态加载路由
 * @param {Object} context 上下文
 * @param {String} routerCode 路由地址
 * @param {Object} params 参数
 * @param {String} renderId 挂载位置id
 */
function renderComp({ context, routerCode, params, renderId, callback }) {
  const routesObj = getRouterObj(routerCode);
  if (!routesObj || !routesObj.componentUrl) {
    ElMessage.error("无访问路由" + routerCode + "权限，或无此路由");
    return;
  }
  //动态挂载路由
  // import(`@/modules/${routesObj.componentUrl}`).then(res => {
  //   const vNode = createVNode(res.default, {
  //     ...params,
  //     onCloseDialog: () => {
  //       if (callback) {
  //         callback();
  //       }
  //     }
  //   });
  //   vNode.appContext = context;
  //   render(vNode, document.querySelector("#" + renderId));
  // });
}

const szdRouter = {
  getRouterCode,
  getRouterObj,
  goNav,
  goNavDg,
  goNavPath,
  getFullPath,
  getUrlParams,
  setUrlParams,
  closeFrameTag,
  renderComp
};

export default szdRouter;
