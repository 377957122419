import Cookies from "js-cookie";

//存储模式
const STORE_MODE = process.env.VUE_APP_STORE_MODE;

const TokenKey = "SZDCORE-Admin-Token";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  Cookies.set(TokenKey, token);
}

export function removeToken() {
  Cookies.remove(TokenKey);
}

export function removeLangDic() {
  localStorage.removeItem("langDic");
  localStorage.removeItem("szdLang");
}

// 外围系统token赋值
export function setOtherTokens(loginOther) {
  if (loginOther != undefined && loginOther != null) {
    //给其它 token赋值
    try {
      Cookies.set(loginOther.tokenInfo.tokenName, encodeURIComponent(loginOther.tokenInfo.token), {
        domain: loginOther.tokenInfo.tokenDomain,
        path: "/"
      });
    } catch (e) {
      console.error(loginOther.destCode + "登录失败:浏览器cookie写入异常");
    }
  }
}

// 存储登录用户信息
const coreLoginKey = "SZDCORE-Admin-CoreLogin";

export function getCoreLogin() {
  if (STORE_MODE == "true") {
    return JSON.parse(sessionStorage.getItem(coreLoginKey));
  } else {
    return false;
  }
}

export function setCoreLogin(obj) {
  if (STORE_MODE == "true") {
    sessionStorage.setItem(coreLoginKey, JSON.stringify(obj));
  }
}

export function removeCoreLogin() {
  sessionStorage.removeItem(coreLoginKey);
}
