import { createStore } from "vuex";
import frmUser from "./frmUser.js";
import components from "./components/components.js";

const store = createStore({
  modules: {
    frmUser,
    components,
  },
});

export default store;
