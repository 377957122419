<template>
  <div>
    <div
      :class="scroll == 0 ? 'nav opacity' : 'nav'"
      @mouseover="handleNavOver"
      @mouseleave="handleNavLeave"
      v-if="screenWidth >= 1100"
    >
      <div class="row-box">
        <div class="nav-logo">
          <img src="../assets/img/logo/logo.png" alt="" @click="handleNav(0)" />
        </div>
        <div class="nav-con">
          <div
            v-for="(item, index) in tabs"
            :key="index"
            class="nav-con-item"
            @click="handleNav(index)"
          >
            {{ item }}
            <div class="border" v-show="navNum == index"></div>
          </div>
          <!-- <div :class="(navNum==0)?'nav-con-item nav-con-item1':'nav-con-item'" @click="handleNav(0)">
						首页
					</div>
					<div :class="(navNum==1)?'nav-con-item nav-con-item1':'nav-con-item'" @click="handleNav(1)">
						产品/方案
					</div>
					<div :class="(navNum==2)?'nav-con-item nav-con-item1':'nav-con-item'" @click="handleNav(2)">
						客户案例
					</div>
					<div :class="(navNum==3)?'nav-con-item nav-con-item1':'nav-con-item'" @click="handleNav(3)">
						关于我们
					</div> -->
        </div>
      </div>
    </div>
    <div
      v-else
      :class="scroll == 0 ? 'nav opacity' : 'nav'"
      @mouseover="handleNavOver"
      @mouseleave="handleNavLeave"
    >
      <div class="nav-box">
        <div class="nav-logo1">
          <img src="../assets/img/logo/logo.png" alt="" @click="handleNav(0)" />
        </div>
        <div class="nav-con1">
          <div style="height: 35px; width: 35px">
            <img
              src="../assets/img/nav/more.png"
              style="width: 100%; height: 100%"
              class="nav-con1-img"
              alt=""
              @click="handleShow(1)"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        width: 100%;
        height: 100vh;
        background-color: rgba(19, 117, 152, 0.71);
        z-index: 3005;
        position: fixed;
        display: flex;
        flex-direction: column;
      "
      v-if="dropShow == 1"
    >
      <div class="drop-down">
        <span @click="handleShow(0)">
          <i class="el-icon-arrow-up" style="font-size: 40px"></i>
        </span>
      </div>
      <div class="drop-down"><span @click="handlePath('/')">首页</span></div>
      <div class="drop-down">
        <span @click="handlePath('/product')">产品/方案</span>
      </div>
      <div class="drop-down">
        <span @click="handlePath('/case')">客户案例</span>
      </div>
      <!-- <div class="drop-down"><span @click="handlePath('/manual')">产品手册</span></div> -->
      <div class="drop-down">
        <span @click="handlePath('/about')">关于我们</span>
      </div>
      <div class="blank" @click="handleShow(0)"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: ["首页", "数字化平台", "产品/方案", "客户案例", "关于我们"],
      navNum: 0, //当前页面位置
      scroll: 0, //判断鼠标滚动位置
      scrollNum: 0, //鼠标移入移出判断
      screenWidth: document.body.clientWidth, //监听页面宽度
      dropShow: 0, //页面下拉是否显示
    };
  },
  mounted() {
    window.addEventListener("scroll", this.menu);
    window.scrollTo(0, 0);
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
  },
  watch: {
    /* 监听*/
    screenWidth(val) {
      this.screenWidth = val;
    },
  },
  methods: {
    //tab颜色切换
    navNumChange(index) {
      this.navNum = index;
    },
    //nav切换
    handleNav(index) {
      if (index != 1) {
        this.navNum = index;
      }
      if (index == 0) {
        this.$router.push({
          path: "/",
        });
      } else if (index == 1) {
        window.open("/platform/home");
        return;
      } else if (index == 2) {
        this.$router.push({
          path: "/product",
        });
      } else if (index == 3) {
        this.$router.push({
          path: "/case",
        });
      } else if (index == 4) {
        this.$router.push({
          path: "/about",
        });
      }
    },
    //滚动判断
    menu() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollNum =
        document.documentElement.scrollTop || document.body.scrollTop;
    },
    //nav鼠标移入
    handleNavOver() {
      if (this.scrollNum == 0) {
        this.scroll = 1;
      }
    },
    //nav鼠标移出
    handleNavLeave() {
      if (this.scrollNum == 0) {
        this.scroll = 0;
      }
    },
    //nav下拉菜单是否显示
    handleShow(num) {
      this.dropShow = num;
    },
    //路由跳转
    handlePath(path) {
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
  height: 80px;
  position: fixed;
  background: #ffffff;
  display: flex;
  justify-content: center;
  z-index: 3000;
  border-bottom: 1px solid #cccccc;
}

.opacity {
  opacity: 0.5;
  box-shadow: 0px 4px 14px 0px rgb(12 37 91 / 10%);
}

.nav-logo {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.nav-logo img {
  width: auto;
  max-width: 180px;
  height: 100%;
  padding: 18px 0;
}

.nav-con {
  height: 100%;
  display: flex;
}

.nav-con:hover {
  cursor: pointer;
}

.nav-con-item {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  margin-right: 30px;
  position: relative;
}

.nav-con-item.active {
  /* color: #3E64FF; */
  /* border-bottom: 4px solid #3E64FF; */
}

.nav-con-item .border {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  height: 4px;
  background: #3e64ff;
}

.nav-con-item:hover .active {
  background: #3e64ff;
}

.nav-box {
  width: 1200px;
  height: 100%;
  display: flex;
}

.nav-logo1 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.nav-logo1 img {
  width: auto;
  max-width: 180px;
  height: 100%;
  padding: 18px 30px;
}

.nav-con1 {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 30px;
  align-items: center;
}

.nav-con1-img:hover {
  cursor: pointer;
}

.drop-down {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  flex-shrink: 0;
}

.drop-down span:hover {
  cursor: pointer;
}

.blank {
  width: 100%;
  height: 100%;
}

.blank:hover {
  cursor: pointer;
}
</style>
