<template>
  <div style="width: 100%; height: 100%; position: relative">
    <iframe v-if="isFrame" :src="iframeSrc" frameborder="0" style="width: 100%; height: 100%" scrolling="auto" />
    <div v-else :id="renderId" class="g-render" style="height: 100%"></div>
  </div>
</template>

<script setup>
  import { getCurrentInstance, onMounted, ref, watch } from "vue";
  import szdRouter from "../../common/utils/goRouter";
  const emits = defineEmits(["close"]);
  const { appContext } = getCurrentInstance();
  const props = defineProps({
    routerCode: {
      type: String,
      default: ""
    },
    params: {
      type: Object,
      default: () => ({})
    }
  });
  //是否iframe渲染
  const isFrame = ref(false);
  //iframe渲染地址
  const iframeSrc = ref("");
  //动态Id
  const renderId = ref("renderWrap");

  onMounted(() => {
    initRender();
  });
  watch(
    () => props.routerCode,
    () => {
      initRender();
    }
  );

  const initRender = () => {
    //获取路由对象
    const routerObj = szdRouter.getRouterObj(props.routerCode);
    isFrame.value = !routerObj.iframeVue;
    if (isFrame.value) {
      //获取地址,通过iframe加载
      iframeSrc.value = szdRouter.goNavPath(props.routerCode, props.params);
    } else {
      renderId.value = "renderWrap" + props.routerCode;
      //动态加载
      szdRouter.renderComp({
        context: appContext,
        routerCode: props.routerCode,
        params: props.params,
        renderId: renderId.value,
        callback: () => {
          emits("close");
        }
      });
    }
  };
</script>

<style scoped lang="scss">
  :deep(.el-dialog) {
    width: auto;
    height: auto;

    .el-dialog__header {
      padding: 0;
      margin-right: 0;
    }

    .el-dialog__body {
      padding: 0.8rem 1.4rem;
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        color: #000;
        font-size: 0.24rem;
        font-weight: 500;
      }
    }
  }
  :deep(.g-render) {
    > * {
      padding: 0 !important;
    }
  }
</style>
