<template>
	<div>
		<div class="contactUs contact_box">
			<div class="c_btn contact_box" @click="isShow = !isShow">
				<img class="img contact_box" src="@/assets/img/contactus/lx.png" alt="">
			</div>
		</div>
		<ul class="el-dropdown-menu el-popper c_pop contact_box" v-if="isShow">
			<li class="el-dropdown-menu__item contact_box" @click="handleSeek">
				<div class="contact_title">
					<img class="c_img contact_box" src="@/assets/img/contactus/online.png" alt="">
					<span>在线咨询</span>
				</div>
				<div class="contact_sub_title">
					在线客服，工作日9:00-18:00，快速响应售前需求
				</div>
			</li>

			<li class="el-dropdown-menu__item contact_box" @click="goGitee">
				<div class="contact_title">
					<img class="c_img contact_box" src="@/assets/img/contactus/lt.png" alt="">
					<span>在线论坛</span>
				</div>
				<div class="contact_sub_title">
					问题答疑，在线提交产品问题，工作人员快速处理
				</div>
			</li>

			<li class="el-dropdown-menu__item contact_box">
				<div class="contact_title">
					<img class="c_img contact_box" src="@/assets/img/contactus/phone.png" alt="">
					<span>联系方式</span>
				</div>
				<div class="contact_sub_title">
					人工咨询，工作日9:00-18:00，联系电话: 193 2211 3655
				</div>
			</li>
		</ul>
	</div>
	<!-- <div class="contactUs" :class="contactUsBox ? 'shadow' : ''" @mouseleave="handleLeaveCon"> -->
	<!-- <div class="contactUs-box" v-if="contactUsBox">
			<div class="contactUs-box-phone" @click="goGitee">在线论坛</div>
			<div class="contactUs-box-phone">在线咨询</div>
			<div class="contactUs-box-phone">手机号</div> -->
	<!-- <div class="contactUs-box-phone">电话：0311-89892288</div> -->
	<!-- <div class="contactUs-box-phone">邮箱：marketing@szdsoft.cn</div> -->
	<!-- </div>
		<div class="contactUs-text" :class="contactUsBox ? '' : 'shadow'" @mouseover="handleOverCon" v-if="!contactUsBox">
			<span>联</span>
			<span>系</span>
			<span>我</span>
			<span>们</span>
		</div> -->
	<!-- </div> -->
</template>

<script>
export default {
	data() {
		return {
			contactUsBox: false,//判断盒子宽度
			contactUsWidth: 40,//盒子宽度
			isShow: false,
		}
	},
	mounted() {
		document.addEventListener('click', (e) => {
			if (e && e.target && e.target.className&& (typeof e.target.className === 'string') && e.target.className.includes('contact_box')) {
				return
			} else {
				this.isShow = false
			}
		})
	},
	methods: {
		//鼠标移入盒子宽度
		handleOverCon() {
			// this.contactUsWidth = 260
			this.contactUsBox = true
		},
		//鼠标移开盒子变化
		handleLeaveCon() {
			// this.contactUsWidth = 40
			this.contactUsBox = false
		},
		goGitee() {
			this.isShow = false;
			window.open('https://gitee.com/szdsdp/demo/issues')
		},
		handleSeek() {
			// 在弹框内打开，无法自适应窗口的大小：比如预设好在线聊天窗口大小，当客户端离线，内容发生变化，样式乱掉
			// this.$openOnline()
			window.open('https://affim.baidu.com/unique_52350406/chat?siteId=20005266&userId=52350406&siteToken=ec1f52d3ca15f9b2a5b976f277920244')
		}
	},
}
</script>

<style scoped>
.contactUs {
	/* height: 130px; */
	position: fixed;
	/* background: rgba(255, 255, 255, 1); */
	right: 10px;
	bottom: 10vh;
	z-index: 3001;
	display: flex;

}

.c_btn {
	text-decoration: none;
	width: 64px;
	height: 64px;
	border-radius: 32px;
	background-color: #fff;
	box-shadow: 0 0 15px 0 rgba(8, 36, 92, .1);
	font-size: 11px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.c_pop {
	position: fixed;
	right: 90px;
	top: auto;
	bottom: calc(10vh - 60px);
	z-index: 3001;
	width: 400px;
	height: 220px;
	left: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px 0;
}

.el-dropdown-menu__item {
	display: flex;
	flex-direction: column;
	 align-items: baseline;
	font-size: 16px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000;
	padding-bottom: 10px;
}

.c_img {
	width: 20px;
	margin-right: 10px;
}

.contact_title {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.contact_sub_title {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #909199;
	line-height: 12px;
	padding-left: 30px;
}


.img {
	width: 31px;
	height: 31px;
}

.contactUs-box {
	width: 150px;
	height: 100%;
	background-color: #fff;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	padding: 20px;
	justify-content: space-around;
}

.contactUs-box-phone {
	margin-bottom: 5px;
	font-size: 14px;
	cursor: pointer;
}

.contactUs-text {
	width: 40px;
	height: 100%;
	color: rgba(29, 35, 37, 1);
	font-size: 14px;
	line-height: 18px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

}

.shadow {
	box-shadow: 0px 4px 14px 0px rgb(12 37 91 / 10%);
}

.contactUs-text:hover {
	cursor: pointer;
}

.contactUs-text span {
	width: 100%;
	display: flex;
	justify-content: center;

}

.case-con-item:hover {
	cursor: pointer;
}
</style>
