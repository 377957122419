<template>
  <div id="app" class="container">
    <router-view />
    <OnlineConsulttation></OnlineConsulttation>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      maskShow: true,
      // key: value
    };
  },
  mounted() { },
  methods: {},
};
</script>
<style lang="scss">
 @import "@/views/platform/assets/style/common.scss";
</style>
<style>
html,
body,
#app {
  width: 100%;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}

.container {
  width: 100%;
  min-width: 1300px;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.row-box {
  width: 1200px;
  display: flex;
}

.col-box {
  width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.col-box1 {
  width: 1200px;
  display: flex;
  flex-direction: column;
}
</style>
