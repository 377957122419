import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store"; //全局引入vuex

import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./assets/style/animate.css";

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import $bus from "./emitter";
import RightTitle from "./views/platform/pages/onlineApi/components/rightPage.vue";
import CustomTable from "./views/platform/pages/onlineApi/components/table.vue";
import CustomCode from "./views/platform/pages/onlineApi/components/code.vue";
import CustomImg from "./views/platform/pages/onlineApi/components/customImg.vue";
import OnlineConsulttation from "./components/onlineConsultation.vue";
// import pagination from "./components/pagination/pagination.vue";
import ComponentTemplate from "@/views/platform/pages/onlineApi/components/componentTemplate.vue";

import "@/mock/index.js";

const req = require.context("@/assets/icons/svg", false, /.svg$/);
req.keys().forEach(svg => {
  req(svg);
});
const app = createApp(App);
app.component("OnlineConsulttation", OnlineConsulttation);
app.config.globalProperties.$bus = $bus;

import SvgIcon from "@/components/svgIcon/svgIconView.vue";
app.component("svg-icon", SvgIcon);
app.component("CustomCode", CustomCode);
app.component("ComponentTemplate", ComponentTemplate);
app.component("RightTitle", RightTitle);
app.component("CustomTable", CustomTable);
app.component("CustomImg", CustomImg);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
// installSzdUpd(app);
// installSearchHelp(app);
// installPopup(app);
// installComponent(app);
// installDirective(app);
// installDateFn(app);
//
// //全局js方法区
import installCommonTool from "./common/tools";
import installCommonFunction from "@/client/common/indexFunction";
import installComponentFunction from "@/client/components/indexFunction";
import installDirective from "@/client/directives/indexDirective";
import installComponent from "@/client/components/indexComponent";

installCommonTool(app);
installComponent(app);
installComponentFunction(app);
installDirective(app);
installCommonFunction(app);
//监听路由跳转
import "./common/utils/permission";

app.component("OnlineConsulttation", OnlineConsulttation);

app.use(store).use(router).use(ElementPlus).mount("#app");
