<template>
    <el-dialog class="onlineDialog" title="" :visible.sync="dialogVisible" width="810px" :before-close="handleClose"
        :modal="false" :show-close="false" destroy-on-close :close-on-click-modal="false" :close-on-press-escape="false">
        <el-button type="default" size="mini" class="o_d_close" @click="handleClose">关闭</el-button>
        <iframe width="100%" height="670"
            src="https://affim.baidu.com/unique_52350406/chat?siteId=20005266&userId=52350406&siteToken=ec1f52d3ca15f9b2a5b976f277920244"
            frameborder="0"></iframe>
    </el-dialog>
</template>

<script>
export default {
    name: 'SzdWebsiteOnlineConsultation',

    data() {
        return {
            dialogVisible: false,
        };
    },

    mounted() {
        this.$bus.$on('openOnlieDialog', () => {
            this.dialogVisible = true;
        })
    },
    beforeDestroy() {
        this.$bus.$off('openOnlieDialog')
    },

    methods: {
        handleClose() {
            this.dialogVisible = false;
        }
    },
};
</script>

<style lang="scss">
.onlineDialog {
    border-radius: 10px;

    .el-dialog {
        box-shadow: none;
        background-color: transparent;
    }

    .el-dialog__header,
    .el-dialog__footer {
        display: none;
    }

    .el-dialog__body {
        background-color: transparent;
        padding: 0;
    }

    .o_d_close {
        position: absolute;
        bottom: 16px;
        right: 335px;
        height: 32px;
    }
}
</style>