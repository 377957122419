import { ElMessage } from "element-plus";

//存储登录用户信息
const coreLoginKey = "SZDCORE-Admin-CoreLogin";

/** 获取登录session
 * loginUser:{
 *  user, //用户
 *  menus, //展示菜单
 *  routesObj, //路由
 *  sysMicro, //微应用
 *  webParams, //web参数
 *  homeConfig, //主题配置
 *  perParams //个人参数
 * }
 * */
function getCoreLogin() {
  return JSON.parse(sessionStorage.getItem(coreLoginKey));
}

/** 设置登录session
 * {
 *  user, //用户
 *  menus, //展示菜单
 *  routesObj, //路由
 *  sysMicro, //微应用
 *  webParams, //web参数
 *  homeConfig, //主题配置
 *  perParams //个人参数
 * }
 * */
function setCoreLogin(loginUser) {
  sessionStorage.setItem(coreLoginKey, JSON.stringify(loginUser));
}

/** 删除登录session */
function removeCoreLogin() {
  sessionStorage.removeItem(coreLoginKey);
}

/** 获取web端参数 */
function getWebParams(key, showError = true) {
  const webParams = JSON.parse(sessionStorage.getItem(coreLoginKey)).webParams;
  if (webParams[key]) {
    return webParams[key];
  } else {
    if (showError) {
      ElMessage.error("获取web端参数key不存在:" + key);
    }
  }
}

const szdStoreSession = {
  getCoreLogin,
  setCoreLogin,
  removeCoreLogin,
  getWebParams
};

export default szdStoreSession;
