<template>
  <div class="com_padding">
    <div class="platform_code">
      <div class="code_block_header">
        <span class="h_type"></span>
        <span class="h_copy" @click="copyText">复制</span>
      </div>
      <pre
        :class="[
          'j-pre',
          multiLine ? 'multiline' : ''
        ]"><code v-if="isJava" class="java xml">{{ code }}</code><code v-else class="html hljs xml javascript">{{ code }}</code></pre>
    </div>
  </div>
</template>

<script>
  import hljs from "highlight.js";
  import "highlight.js/styles/xcode.min.css";
  import java from "highlight.js/lib/languages/java.js";
  import xml from "highlight.js/lib/languages/xml.js";

  export default {
    name: "SzdWebsiteCode",
    props: {
      code: {
        type: String,
        default: ""
      },
      isJava: {
        type: Boolean,
        default: false
      },
      multiLine: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {};
    },

    mounted() {
      this.renderCode();
    },
    updated() {
      this.renderCode("updated");
    },
    methods: {
      renderCode() {
        const preCodes = document.querySelectorAll(".j-pre code");
        for (let i = 0, l = preCodes.length; i < l; i++) {
          if (this.isJava) {
            hljs.registerLanguage("java", java);
            hljs.highlightBlock(preCodes[i], { language: "java" });
          } else {
            hljs.registerLanguage("xml", xml);
            hljs.highlightBlock(preCodes[i]);
          }
        }
      },
      copyText() {
        const textarea = document.createElement("textarea");
        textarea.value = this.code;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        this.$message.success("复制成功");
      }
    }
  };
</script>

<style lang="scss" scoped>
  .j-pre {
    background-color: #fafafa;
    font-size: 14px;
  }
  .platform_code {
    transition: none !important;
    position: relative;
    //font-size: 14px;
    //font-weight: 400;
    //color: #2b2f36;
    //background-color: #f5f6f7;
    //border: 1px solid #dee0e3;
    //border-radius: 4px;
    //padding-bottom: 2px;
    //padding-top: 10px;
    //margin-bottom: 12px;
    //width: 100%;
    //height: 100%;

    &:hover {
      .code_block_header {
        opacity: 1;
      }
    }
    pre {
      overflow-x: auto;
      &.j-pre.multiline {
        > code {
          white-space: break-spaces;
          word-break: break-all;
        }
      }
    }
    code {
      font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
      font-size: 1em;
      background-color: transparent;
    }

    .code_block_header {
      min-width: 92px;
      height: 24px;
      font-size: 14px;
      border-radius: 4px 4px 0 0;
      padding: 4px 8px 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      opacity: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #646a73;

      .h_copy {
        cursor: pointer;
      }
    }
  }
</style>
