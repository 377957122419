import request from '../../../common/utils/request'
// 登录方法
export function login(custId, password, code, uuid, loginFlag) {
  const data = {
    custId,//用户登录id
    password,//用户密码
    code,//验证码
    uuid,//验证码对应后端uuid
    loginFlag
  }
  return request({
    url: '/sdop/eco/login',
    method: 'post',
    data: data
  })
}
// 登录方法
export function loginPhone(data) {
  return request({
    url: '/sdop/eco/login',
    method: 'post',
    data: data
  })
}
// 获取用户详细信息
export function getUserInfo() {
  return request({
    url: '/sdop/eco/cust/info',
    method: 'get'
  })
}
// 更新安全认证的客户信息
export function updSecurity() {
  return request({
    url: '/sdop/eco/login/updSecurity',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/sdop/eco/login/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/sdop/eco/login/captchaImage',
    method: 'get'
  })
}
// 登录-发送手机验证码
export function sendSmsCode(data) {
  return request({
    url: '/sdop/eco/login/smsCode',
    method: 'post',
    data: data
  })
}
// 注册-发送手机验证码
export function signupsendSmsCode(data) {
  return request({
    url: '/sdop/eco/signup/smsCode/',
    method: 'post',
    data: data
  })
}
// 注册账号
export function signUp(data) {
  return request({
    url: '/sdop/eco/signup/signup',
    method: 'post',
    data: data
  })
}