import {
    login,
    logout,
    getUserInfo
} from '../views/login/api/login'
import {
    getToken,
    setToken,
    removeToken,
    getCoreLogin,
    setCoreLogin,
    removeCoreLogin
} from '../common/utils/authToken'

const frmuser = {
    state: {
        token: getToken(), //获取token
        userData: '', //用户信息
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_USERDATA: (state, userData) => {
            state.userData = userData
        },
    },
    actions: {
        // 登录
        Login({
                  commit
              }, userInfo) {
            const custId = userInfo.custId.trim()
            const password = userInfo.password
            const code = userInfo.code
            const uuid = userInfo.uuid
            const loginFlag = userInfo.loginFlag
            return new Promise((resolve, reject) => {
                login(custId, password, code, uuid, loginFlag).then(res => {
                    if (res.code === 200) {
                        removeCoreLogin()
                        setToken(res.data.token) //给token赋值
                        commit('SET_TOKEN', res.data.token) //给全局token赋值
                        resolve(res)
                    } else {
                        resolve(false)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息
        GetUserInfo({
                        commit
                    }) {
            return new Promise((resolve) => {
                let store_res = getCoreLogin()
                if (store_res) {
                    prossUserInfo({
                        commit
                    }, store_res)
                    resolve(store_res)
                } else {
                    getUserInfo().then(res => {
                        if (res != undefined && res.code === 200) {
                            setCoreLogin(res)
                            prossUserInfo({
                                commit
                            }, res)
                        }
                        resolve(res)
                    })
                }
            })
        },

        // 退出系统
        LogOut({
                   commit,
                   state
               }) {
            return new Promise((resolve, reject) => {
                logout(state.token).then(() => {
                    commit('SET_TOKEN', '')
                    commit('SET_USERDATA', {})
                    removeToken()
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 用户没有登录成功或者拉去路由失败退出登录
        FedLogOut({
                      commit
                  }) {
            return new Promise(resolve => {
                commit('SET_TOKEN', '')
                commit('SET_USERDATA', {})
                removeToken()
                resolve()
            })
        },
    }
}

// 获取用户信息-处理
function prossUserInfo({
                           commit
                       }, res) {
    const user = res.data
    commit('SET_USERDATA', user)
}

export default frmuser
