<template>
	<div>
		<navTab ref="navTab"></navTab>
		<div class="banner">
			<div style="position: absolute;left: 30px;top: 43vh;z-index: 10;">
				<i class="el-icon-arrow-left banner-i" style="font-size: 60px;color: #FFFFFF;"
					@click="handeChange('left')"></i>
			</div>
			<div style="position: absolute;right: 30px;top: 43vh;z-index: 10;">

				<i class="el-icon-arrow-right banner-i" style="font-size: 60px;color: #FFFFFF;"
					@click="handeChange('right')"></i>
			</div>
			<el-carousel :interval="8000" arrow="never" height="95vh" trigger="click" ref="cardShow">
				<el-carousel-item>
					<div class="banner-item">
						<div class="banner-item-con">
							<div class="banner-item-con-h1">
								数字化平台，助力企业内部IT生态建设，降本增效，保驾护航
							</div>
							<div class="banner-item-con-h3">
								整合业务服务能力、数据服务能力、技术实现能力，系统间无缝集成，系统落地解耦
							</div>
							<div class="banner-item-con-h3" style="margin-top: 0;">
								产品免费，技术共享，推动行业技术规范
							</div>
							<!-- <div class="banner-item-con-btn">
								产品免费，技术共享，推动行业技术规范
							</div> -->
						</div>
						<img class="banner-img" src="../assets/img/banner/banner1.jpg" alt="">
					</div>
				</el-carousel-item>
				<el-carousel-item>
					<div class="banner-item">
						<div class="banner-item-con">
							<!-- <div class="banner-item-con2-btn">
								数据驱动，智享财务
							</div> -->
							<div class="banner-item-con-h1">
								低成本，快落地，一站服务
							</div>
							<div class="banner-item-con2-h2">
								<div class="banner-item-con2-h2-text">
									SAP + 数字化平台 + 财务共享 + 电子影像 + 电子档案 + 资金管理 + 金税直连
								</div>
							</div>
						</div>
						<img class="banner-img" src="../assets/img/banner/banner2.jpg" alt="">
					</div>
				</el-carousel-item>
				<el-carousel-item>
					<div class="banner-item">
						<div class="banner-item-con">
							<div class="banner-item-con2-h2">
								<div class="banner-item-con2-h2-text" style="font-size: 28px;">
									智享资金管理，提升企业生命力
								</div>
							</div>
							<div class="banner-item-con-h1" style="margin-top: 30px;margin-bottom: 30px;font-size: 24px;">
								增强资金管控能力，实现减员增效、资金创收
							</div>
							<div class="banner-item-con-h1" style="font-size: 24px;">
								20个工作日上线，SAP无缝集成
							</div>
						</div>
						<img class="banner-img" src="../assets/img/banner/banner7.jpg" alt="">
					</div>
				</el-carousel-item>
				<el-carousel-item>
					<div class="swiper-slide banner-item1">
						<div class="banner-item-con4">
							<div class="banner-item-con4-h1" style="margin-top: 35px;font-size: 28px;">
								智享金税直连，数电开票流程自动化，直线提升工作效率
							</div>
							<div class="banner-item-con4-h1" style="margin-top: 20px;">
								5个工作日上线， SAP无缝集成
							</div>
						</div>
						<img class="banner-img" src="../assets/img/banner/banner9.jpg" alt="">
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="programme">
			<div class="programme-title">
				高效、稳定、安全的智能方案
			</div>
			<div class="programme-content">
				<div class="programme-content-item" v-for="(item, index) in programme" :key="index"
					@click="handlePro(index)">
					<div class="programme-content-item-position">
						<img :src="item.img" alt="">
					</div>
					<div class="programme-content-item-title">
						{{ item.title }}
					</div>
					<div class="programme-content-item-con">
						{{ item.content }}
					</div>
					<!-- <div class="programme-content-item-con" style="width: 98%;padding-left: 25px;" v-if="index == 0">
						{{ item.content }}
					</div>
					<div class="programme-content-item-con" style="width: 98%;padding-left: 25px;" v-if="index == 1">
						{{ item.content }}
					</div>
					<div class="programme-content-item-con" style="width: 99%;padding-left: 27px;" v-if="index == 2">
						{{ item.content }}
					</div>
					<div class="programme-content-item-con" style="width: 100%;padding-left: 28px;padding-right: 21px;"
						v-if="index == 3">
						{{ item.content }}
					</div> -->
				</div>
			</div>
		</div>
		<div class="solution">
			<div class="row-box">
				<div class="solution-content">
					<div class="solution-content-title">
						<div>覆盖多业务场景的</div>
						<div>成熟解决方案</div>
					</div>
					<div class="solution-content-con">
						产品研发团队由拥有十几年SAP经验的专家组成，深入理解企业管理思路和信息化应用场景，以产品为基，结合企业实际，提供业务贴合性高、跨多应用系统的完整解决方案。
					</div>
					<div class="solution-content-btn" @click="handlePath('/product')">
						查看更多
					</div>
				</div>
				<div class="solution-img">
					<img src="../assets/img/home/solution.jpg" @mouseover="handleOver" @mouseleave="handleLeave"
						:class="(overNum == 1) ? 'animate__animated animate__heartBeat' : ''" alt="">
				</div>
			</div>
		</div>
		<div class="about">
			<div class="row-box ">
				<div class="about-img">
					<img src="../assets/img/home/about.jpg" alt="">
				</div>
				<div class="about-con">
					<div class="about-con-about">
						ABOUT US
					</div>
					<div class="about-con-title">
						<div>致力于</div>
						<div> 赋能企业信息化建设</div>
					</div>
					<div class="about-con-con">
						河北尚智得信息技术有限公司专注于企业SAP系统与其他系统集成的产品研发及实施，致力于为企业提供更高效、更便捷的信息化解决方案，公司现有财务共享、资金管理、费控管理、结算管理、金税直连、电子影像和基础中台等自研产品。
					</div>
					<div class="about-con-ensure">
						<div class="about-con-ensure-item" v-for="(item, index) in auoutList" :key="index">
							<img src="../assets/img/home/about-check.png" alt="">
							<span>
								{{ item }}
							</span>
						</div>
					</div>
					<div class="about-con-btn" @click="handlePath('/about')">
						<span> 了解我们</span><img src="../assets/img/home/about-arrow.png" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="middleground">
			<div class="col-box">
				<div class="middleground-title">
					数字化平台，助力企业内部IT生态建设，降本增效，保驾护航
				</div>
				<div class="middleground-con">
					数字化平台提炼了技术标准、通用数据管理、后台管理、系统监控、系统间集成、OCR识别等能力，同时还具有企业应用级的代码模板库，助力系统快速落地。数字化平台助力企业整合各系统业务服务能力、数据服务能力、技术实现能力，系统间无缝集成，技术规范化，系统落地解耦，带动企业IT建设规范、降本增效，推动行业技术规范。
				</div>
			</div>
		</div>
		<div class="case">
			<div class="case-title">
				助力各行业数智化成功案例
			</div>
			<div class="case-con">
				<div class="case-con-imgs">
					<div class="case-con-imgs-item" style="border-left: 1px solid #CCCCCC;">
						<img src="../assets/img/pubuilc/神威药业.png" style="width: 100%;height: 100%;" alt="">
					</div>
					<div class="case-con-imgs-item">
						<img src='../assets/img/pubuilc/中鼎集团.png' style="width: 100%;height: 100%;" alt="">
					</div>
					<div class="case-con-imgs-item">
						<img src='../assets/img/pubuilc/润田.png' style="width: 100%;height: 100%;" alt="">
					</div>
					<div class="case-con-imgs-item">
						<img src='../assets/img/pubuilc/Agilent.png' style="width: 100%;height: 100%;" alt="">
					</div>
					<div class="case-con-imgs-item">
						<img src="../assets/img/pubuilc/大唐移动.png" style="width: 100%;height: 100%;" alt="">
					</div>
					<div class="case-con-imgs-item" style="border-left: 1px solid #CCCCCC;">
						<img src='../assets/img/pubuilc/正大集团.png' style="width: 100%;height: 100%;" alt="">
					</div>
					<div class="case-con-imgs-item">
						<img src='../assets/img/pubuilc/中国石油.png' style="width: 100%;height: 100%;" alt="">
					</div>
					<div class="case-con-imgs-item">
						<img src='../assets/img/pubuilc/科隆集团.png' style="width: 100%;height: 100%;" alt="">
					</div>
					<div class="case-con-imgs-item">
						<img src='../assets/img/pubuilc/中国航油.png' style="width: 100%;height: 100%;" alt="">
					</div>
					<div class="case-con-imgs-item">
						<img src='../assets/img/pubuilc/国家电网公网.png' style="width: 100%;height: 100%;" alt="">
					</div>
					<div class="case-con-imgs-item"
						style=" border-left: 1px solid #CCCCCC;border-bottom: 1px solid #CCCCCC;">
						<img src='../assets/img/pubuilc/中核集团.png' style="width: 100%;height: 100%;" alt="">
					</div>
					<div class="case-con-imgs-item" style="border-bottom: 1px solid #CCCCCC;">
						<img src='../assets/img/pubuilc/江西省铁路.png' style="width: 100%;height: 100%;" alt="">
					</div>
					<div class="case-con-imgs-item" style="border-bottom: 1px solid #CCCCCC;">
						<img src='../assets/img/pubuilc/远东宏信.png' style="width: 100%;height: 100%;" alt="">
					</div>
					<div class="case-con-imgs-item" style="border-bottom: 1px solid #CCCCCC;">
						<img src='../assets/img/pubuilc/中国烟草.png' style="width: 100%;height: 100%;" alt="">
					</div>

					<div class="case-con-imgs-more" @click="handlePath('/case')">
						<span class="case-con-imgs-more-span">更多案例</span> <img
							src="../assets/img/home/case-con-imgs-more.png">
					</div>
				</div>
				<div class="case-con-bank">
					<div class="case-con-bank-title">
						<div class="case-con-bank-title-line"></div>
						<div class="case-con-bank-title-text">合作银行</div>
						<div class="case-con-bank-title-line"></div>
					</div>
					<div class="case-con-bank-box"></div>
					<div class="case-con-bank-con">
						<div class="case-con-bank-con-imgs">
							<div class="case-con-bank-con-imgs-prev imgs-prev" @click="handlePrev">
								<img src="../assets/img/home/prev.png" alt="">
							</div>
							<div class="box">
								<div class="case-con-bank-con-imgs-con" :style="{ 'left': left + 'px' }">
									<div class="case-con-bank-con-imgs-con-img" v-for="(item, index) in caseList"
										:key="index">
										<img :src="item" alt="" v-if="item !== '更多'">
										<span v-else @click="handlePath('/about')">{{ item }}银行</span>
									</div>

								</div>
							</div>

							<div class="case-con-bank-con-imgs-prev imgs-next" @click="handleNext">
								<img src="../assets/img/home/next.png" alt="">
							</div>
						</div>
					</div>
					<div class="case-con-bank-box1"></div>
				</div>
			</div>
			<div class="case-con-bank-box1"></div>
			<div class="case-con-bank-box1"></div>
		</div>
		<footerTab></footerTab>
		<contact></contact>
	</div>
</template>
<script>
import navTab from "@/components/navTab.vue"
import footerTab from "@/components/footerTab.vue"
import contact from "@/components/contact.vue"
import Cookies from 'js-cookie'

export default {
	components: {
		navTab,
		footerTab,
		contact
	},
	props: {
		heightBanner: {
			type: String,
			default: '600px'
		}
	},
	data() {
		return {
			programme: [{
				img: require('../assets/img/home/programme.png'),
				title: '数字化平台',
				content: '整合各系统业务服务能力、数据服务能力、技术实现能力，系统间无缝集成，技术规范化，系统落地解耦……'
			},
			{
				img: require('../assets/img/home/programme.png'),
				title: '财务共享',
				content: '建立共享服务中心，推动集团化公司实施创新驱动发展战略，建立价值型财务管理体系，促进财务转型……'
			},
			{
				img: require('../assets/img/home/programme.png'),
				title: '电子影像',
				content: '支持影像多端采集、OCR识别，支持混贴辨识、影像修正，影像智能分类、快递填单，支持票夹管理、票据转让……'
			},
			{
				img: require('../assets/img/home/programme.png'),
				title: '电子档案',
				content: '遵照国家政策要求标准设计，支持多种采集方式，提供插件和源码，根据业务快速构建档案管理模型，实物条码管理……'
			},
			{
				img: require('../assets/img/home/programme.png'),
				title: '资金管理',
				content: '覆盖全集团的资金监控体系，建立“统一平台、统一管理、统一理财”的管理系统，提高资金效益，防范资金风险……'
			},
			{
				img: require('../assets/img/home/programme.png'),
				title: '金税直连',
				content: '满足客户个性化开票要求，自动拆分合并开票数据，减少人为干预，降低人工成本，实现自动化智能化开票……'
			},
			{
				img: require('../assets/img/home/programme.png'),
				title: 'SAP',
				content: '企业SAP系统与其他系统集成的产品研发及实施，致力于为企业提供更高效、便捷的信息化解决方案……'
			}
			],
			overNum: 0, //判断动画是否加载
			auoutList: ['全面提供可视化的实施', '全面提升项目交付质量', '全面降低项目交付成本'], //渲染页面
			left: 0, //做移动距离
			caseList: [require('../assets/img/pubuilc/icbc.png'), require('../assets/img/pubuilc/abc.png'), require(
				'../assets/img/pubuilc/boc.png'),
			require('../assets/img/pubuilc/ccb.png'),
			require('../assets/img/pubuilc/ccb-zx.png'), require('../assets/img/pubuilc/xy.png'), require(
				'../assets/img/pubuilc/zhaoshang_chang.jpg'), '更多'
			],
			prevNum: 6, //合作银行个数
		}
	},
	mounted() {
		this.$refs.navTab.navNumChange(0)

		Cookies.set('name', 'value', { domain: 'szdsoft.cn' })
	},
	methods: {
		//左右切换
		handeChange(val) {
			if (val === 'right') {
				this.$refs.cardShow.next()
			} else {
				this.$refs.cardShow.prev()
			}
		},
		//页面跳转
		handlePro(index) {
			if (index == 0) {
				this.handlePath('/pro-platform')
			} else if (index == 1) {
				this.handlePath('/pro-share')
			} else if (index == 2) {
				this.handlePath('/pro-image')
			} else if (index == 3) {
				this.handlePath('/pro-archives')
			} else if (index == 4) {
				this.handlePath('/pro-fund')
			} else if (index == 5) {
				this.handlePath('/pro-tax')
			} else if (index == 6) {
				// this.handlePath('/pro-basic')
			}
		},
		//查看更多
		handlePath(path) {
			this.$router.push({
				path: path
			})
		},
		//动画鼠标移入
		handleOver() {
			this.overNum = 1
		},
		//动画鼠标移出
		handleLeave() {
			this.overNum = 0
		},
		//了解我们
		hanleKnowUs() {
			location.href = 'about.html'
		},
		//更多案例
		handleMoreCases() {
			location.href = 'case.html'
		},
		//合作银行上一个
		handlePrev() {
			if (this.left == 0) {
				// this.$message.success('这已经是第一个了')
			} else {
				this.left += 183
				this.prevNum--
			}
		},
		//合作银行下一个
		handleNext() {
			if (this.prevNum > this.caseList.length - 1) {
				// this.$message.success('这已经是最后一个了')
			} else {
				this.left -= 183
				this.prevNum++
			}
		},
		//更多银行
		handleBank() {
			location.href = 'about.html'
		},
	}
}
</script>
<style></style>
<style scoped>
.banner {
	width: 100%;
	min-width: 1200px;
	height: 95vh;
	position: relative;
}

.banner-i:hover {
	cursor: pointer;
}

.banner-item {
	position: relative;
	display: flex;
	padding-top: 13%;
	height: 95vh;
}

.banner-item1 {
	/* position: relative; */
	display: flex;
	padding-top: 15%;
}

.banner-img {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.banner-item-con {
	width: 1200px;
	z-index: 300;
	display: flex;
	flex-direction: column;
	padding-left: 200px;
}

.banner-item-con-h1 {
	font-size: 28px;
	color: #fff;
}

.banner-item-con-h3 {
	width: 100%;
	font-size: 20px;
	color: #FFFFFF;
	margin: 30px 0;
}

.banner-item-con-btn {
	width: 460px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #4f8cfe;
	border-radius: 50px;
	font-size: 18px;
	color: #FFFFFF;
}

.banner-item-con2-btn {
	width: 365px;
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 45px;
	font-size: 24px;
	background-color: #4f8cfe;
	color: #FFFFFF;
	margin-bottom: 30px;
}

.banner-item-con2-h2 {
	font-size: 20px;
	color: #fff;
	width: 100%;
	display: flex;
	align-items: center;
	margin-top: 30px;
}

.banner-item-con2-h2-text {
	font-size: 24px;
	height: 100%;
}

.banner-item-con4 {
	width: 1200px;
	z-index: 300;
	display: flex;
	flex-direction: column;
	padding-left: 200px;
}

.banner-item-con4-h1 {
	font-size: 24px;
	color: #FFFFFF;
}

.swiper-container {
	width: 100%;
	height: 100%;
}

/* .swiper-pagination {
		display: flex;
		justify-content: center;
	} */

.swiper-pagination-bullet-active {
	background-color: #3E64FF
}

.swiper-button-prev {
	width: 80px;
	color: #FFFFFF;
	opacity: 0.3;
}

.swiper-button-next {
	width: 80px;
	color: #FFFFFF;
	opacity: 0.3;
}

.my-bullet {
	display: flex;
	width: 14px;
	height: 14px;
	background-color: #FFFFFF;
	border-radius: 50%;
	margin: 0 5px;
}

.my-bullet-active {
	background-color: #3E64FF;
}

.programme {
	width: 100%;
	/* height: 554px; */
	display: flex;
	flex-direction: column;

}

.programme-title {
	width: 100%;
	height: 172px;
	display: flex;
	justify-content: center;
	font-size: 30px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #000000;
	padding-top: 80px;
	flex-shrink: 0;
}

.programme-content {
	/* width: 100%; */
	width: 1200px;
	margin: 0 auto;
	/* min-width: 1200px; */
	height: 100%;
	display: flex;
	justify-content: center;
	padding-top: 42px;
	flex-wrap: wrap;
}

.programme-content-item {
	/* width: 255px; */
	width: calc(25% - 52px);
	height: 250px;
	background: #FFFFFF;
	box-shadow: 0px 5px 23px 0px rgba(181, 221, 255, 0.61);
	border-radius: 10px;
	position: relative;
	/* margin-right: 52px; */
	margin: 0 26px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 60px;
	cursor: pointer;
}

.programme-content-item:last-child {
	margin-right: 0;
}

.programme-content-item-position {
	width: 98px;
	height: 98px;
	position: absolute;
	top: -42px;
	/* left: 79px; */
}

.programme-content-item-position img {
	width: 100%;
	height: 100%;
}

.programme-content-item-title {
	width: 100%;
	padding-top: 86px;
	display: flex;
	justify-content: center;
	font-size: 20px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #000000;
}

.programme-content-item-title:hover {
	cursor: pointer;
}

.programme-content-item-con {
	width: 100%;
	padding: 20px 20px 0 20px;
	font-size: 14px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #565656;
	line-height: 24px;
}

.solution {
	width: 100%;
	height: 500px;
	background: #000000;
	display: flex;
	justify-content: center;

}

.solution-content {
	width: 50%;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
}

.solution-content-title {
	font-size: 30px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 42px;
	padding-top: 87px;
	display: flex;
	flex-direction: column;
}

.solution-content-con {
	width: 465px;
	font-size: 16px;
	font-family: PingFang-SC-Regular, PingFang-SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 26px;
	padding-top: 50px;
	padding-bottom: 30px;
}

.solution-content-btn {
	width: 110px;
	height: 36px;
	border: 1px solid rgba(255, 255, 255, 0.8);
	font-size: 16px;
	font-family: PingFang-SC-Regular, PingFang-SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.solution-content-btn:hover {
	cursor: pointer;
}

.solution-img {
	width: 590px;
	height: 290px;
	margin-top: 68px;
}

.solution-img img {
	width: 100%;
	height: 100%;
}

.about {
	width: 100%;
	height: 571px;
	display: flex;
	justify-content: center;
}

.about-img {
	width: 50%;
	height: 100%;
	flex-shrink: 0;
	display: flex;
	align-items: center;
}

.about-img img {
	width: 523px;
	height: 360px;
}

.about-con {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding-left: 82px;
}

.about-con-about {
	font-size: 16px;
	font-family: SFUIDisplay-Regular, SFUIDisplay;
	font-weight: 400;
	color: #3E64FF;
	padding-top: 77px;
}

.about-con-title {
	font-size: 30px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #000000;
	line-height: 46px;
	padding-top: 7px;
	display: flex;
	flex-direction: column;
}

.about-con-con {
	font-size: 16px;
	font-family: PingFang-SC-Regular, PingFang-SC;
	font-weight: 400;
	color: #4A4A4A;
	line-height: 32px;
	padding-top: 11px;
}

.about-con-ensure {
	width: 100%;
	height: 141px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.about-con-ensure-item {
	display: flex;
}

.about-con-ensure-item img {
	width: 22px;
	height: 22px;
	flex-shrink: 0;
}

.about-con-ensure-item span {
	width: 100%;
	font-size: 14px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #000000;
	line-height: 20px;
	padding-left: 13px;
}

.about-con-btn {
	width: 174px;
	height: 48px;
	background: #3E64FF;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
}

.about-con-btn:hover {
	cursor: pointer;
}

.middleground {
	width: 100%;
	height: 400px;
	background-image: url(../assets/img/home/middleground.jpg);
	display: flex;
	justify-content: center;
}

.middleground-title {
	font-size: 30px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #FFFFFF;
}

.middleground-con {
	padding-top: 40px;
	width: 620px;
	font-size: 16px;
	font-family: PingFang-SC-Regular, PingFang-SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 26px;
}

.case {
	width: 100%;
	height: auto;
	background: url(../assets/img/home/case.png) no-repeat;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.case-title {
	width: 100%;
	height: 152px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #000000;
	flex-shrink: 0;
}

.case-con {
	width: 1200px;
	background: #FFFFFF;
	box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: column;
}

.case-con-imgs {
	width: 100%;
	padding: 30px 40px 0 40px;
	display: flex;
	flex-wrap: wrap;
	flex-shrink: 0;
}

.case-con-imgs-item {
	width: 224px;
	height: 77px;
	border-top: 1px solid #CCCCCC;
	border-right: 1px solid #CCCCCC;
	/* padding: 20px 25px 20px 25px; */
}


.case-con-imgs-more {
	width: 224px;
	height: 77px;
	background: #FFFFFF;
	border-top: 1px solid #CCCCCC;
	border-right: 1px solid #CCCCCC;
	border-bottom: 1px solid #CCCCCC;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #3E64FF;
}

.case-con-imgs-more:hover {
	cursor: pointer;
}

.case-con-imgs-more-span {
	margin-right: 7px;
}

.case-con-bank {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 41px;
}

.case-con-bank-title {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	flex-shrink: 0;
	padding: 0 30px;
}

.case-con-bank-title-line {
	width: 100%;
	height: 1px;
	background-color: #cdcdcd;
}

.case-con-bank-title-text {
	width: 140px;
	height: 100%;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.case-con-bank-con {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0 25px;
}

.case-con-bank-con-imgs {
	width: 100%;
	height: 60px;
	display: flex;
	position: relative;
}

.case-con-bank-con-imgs-prev {
	width: 30px;
	height: 100%;
	background-color: #F0F1F6;
	flex-shrink: 0;
}

.case-con-bank-box {
	width: 100%;
	height: 30px;
}

.case-con-bank-box1 {
	width: 100%;
	height: 40px;
}

.case-con-bank-box2 {
	width: 100%;
	height: 92px;
}

.imgs-prev {
	position: absolute;
	left: 0;
	top: 0;
}

.imgs-prev:hover {
	cursor: pointer;
}

.imgs-next {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0;
	top: 0;
}

.imgs-next:hover {
	cursor: pointer;
}

.box {
	width: 1100px;
	height: 60px;
	position: relative;
	overflow: hidden;
	left: 30px;
}

.case-con-bank-con-imgs-con {
	height: 100%;
	padding: 10px 20px;
	display: flex;
	overflow: hidden;
	position: absolute;
}

.case-con-bank-con-imgs-con-img {
	width: 153px;
	height: 40px;
	margin-right: 28px;
	flex-shrink: 0;
	border: 1px solid #CCCCCC;
}

.case-con-bank-con-imgs-con-img img {
	width: 100%;
	height: 100%;
}

.case-con-bank-con-imgs-con-img span {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #3E64FF;
}

.case-con-bank-con-imgs-con-img span:hover {
	cursor: pointer;
}

.animate__animated.animate__heartBeat {
	--animate-duration: 4s;
}
</style>
