import { szdTLValidExternal, szdTLIsNull, szdTLIsNullMsg, szdTLCheckNum, szdTLCheckNumMsg, szdTLCheck } from "../../common/tools/validate.js";

window.log = function (value, key = "===========>") {
  console.log(key, value);
};
const installCommonTool = app => {
  app.config.globalProperties.szdTLIsNull = szdTLIsNull;
  app.config.globalProperties.szdTLIsNullMsg = szdTLIsNullMsg;
  app.config.globalProperties.szdTLCheckNum = szdTLCheckNum;
  app.config.globalProperties.szdTLCheckNumMsg = szdTLCheckNumMsg;
  app.config.globalProperties.szdTLValidExternal = szdTLValidExternal;
};

export default installCommonTool;
