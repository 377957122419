<template>
  <el-dialog :title="title" v-model="dgOpen" :before-close="close" align-center destroy-on-close append-to-body>
    <div v-szd-dialog="[width, height]"></div>
    <szd-ifr :routerCode="routerCode" :params="params" @close="close"></szd-ifr>
  </el-dialog>
</template>

<script setup>
  import { ref, provide } from "vue";
  const emits = defineEmits(["closeDialog"]);
  const props = defineProps({
    routerCode: {
      type: String,
      default: ""
    },
    params: {
      type: Object
    },
    title: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: "95%"
    },
    height: {
      type: String,
      default: "85%"
    }
  });
  const closeFlag = ref(false);
  provide("closeFlag", closeFlag.value);
  const dgOpen = ref(true);
  const close = () => {
    setTimeout(() => {
      emits("closeDialog");
    }, 100);
  };
</script>

<style scoped lang="scss">
  :deep(.el-dialog) {
    width: auto;
    height: auto;

    .el-dialog__header {
      padding: 0;
      margin-right: 0;
    }

    .el-dialog__body {
      padding: 0.8rem 1.4rem;
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        color: #000;
        font-size: 0.24rem;
        font-weight: 500;
      }
    }
  }
</style>
