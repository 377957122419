<template>
  <div class="flex_shrink" :style="{ width, height }">
    <div class="com_img">
      <img class="p_img" :src="url" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SzdWebsiteCustomImg",
  props: {
    url: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
  },
};
</script>