const state = {
	memoryDict: [],
	memoryBaseField: []
}
const mutations = {
	SET_memoryDict(state, data) {
		for (let i = 0; i < state.memoryDict.length; i++) {
			if (state.memoryDict[i].key == data.key) {
				return
			}
		}
		state.memoryDict.push(data)
	},
	SET_memoryBaseField(state, data) {
		for (let i = 0; i < state.memoryBaseField.length; i++) {
			if (state.memoryBaseField[i].key == data.key) {
				return
			}
		}
		state.memoryBaseField.push(data)
	},
	SET_memoryClear(state) {
		state.memoryDict = []
		state.memoryBaseField = []
	},
}

export default {
	namespaced: true,
	state,
	mutations
}
