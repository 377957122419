<template>
  <div class="app-sub-container app-sub-sub flex-y flex-1">
    <!--  工具栏  -->
    <tableToolbar v-if="!_lay.tBar.hide" ref="refToolbar" v-model:lay="_lay" :dataObj="_dataObj" @clickToolbar="handleClickToolbar" />
    <!--  输出表格  -->
    <div class="flex-1 ovh">
      <tableComp
        ref="refTableComp"
        v-model:lay="_lay"
        v-model:dataObj="_dataObj"
        v-model:tParObj="_tParObj"
        @handleRequest="handleRequestImp"
        @handleEvent="handleEventImp"
        @handleRowEvent="handleRowEventImp"
        v-bind="$attrs" />
    </div>
    <szd-pagination
      v-if="_lay.layout.pagination !== '0'"
      :total="_page.total"
      v-model:page="_page.pageNum"
      v-model:limit="_page.pageSize"
      :pageSizes="_page.pageSizes"
      :position="_page.position"
      @pagination="handleRequestImp" />
  </div>
</template>

<script setup>
  import { computed, ref, defineProps, defineEmits, onMounted, watch, defineExpose } from "vue";
  import { initTableLay, initLayVarDef } from "./js/tableParam";
  import { dataSortFilter } from "./js/tableFuncSortFilter";
  import { getVarDef } from "../api/table";
  import tableToolbar from "./tableToolbar.vue";
  import tableComp from "./tableComp.vue";

  const props = defineProps({
    //表格样式
    layout: {
      type: Object,
      default: {}
    },
    //字段目录
    fieldCat: {
      type: Array,
      default: []
    },
    //工具类按钮
    toolbar: {
      type: Object,
      default: {}
    },
    //排序规则 数组[{field,order}]
    sortRule: {
      type: Array,
      default: []
    },

    //输出数据
    data: {
      type: Array,
      required: true,
      default: () => []
    },

    //分页
    page: {
      type: Object,
      default: {}
    }
  });
  const emit = defineEmits(["handleRequest", "handleEvent", "handleRowEvent", "update:data", "update:page"]);
  const refToolbar = ref();
  const refTableComp = ref();
  //表格布局
  const _lay = computed(() => {
    return initTableLay(props.layout, props.fieldCat, props.toolbar, props.sortRule);
  });
  //表格数据
  const _dataObj = computed({
    get: () => props.data,
    set: val => {
      emit("update:data", val);
    }
  });
  //分页
  const _page = computed({
    get: () => props.page,
    set: val => {
      emit("update:page", val);
    }
  });
  //表格处理参数
  const _tParObj = ref({
    filter: false, //执行过过滤
    merge: {} //合并行规则
  });
  //初始化数据
  onMounted(() => {
    getLayoutVarDef();
  });

  //获取缺省值
  const getLayoutVarDef = () => {
    console.log("1", 2);
    if (_lay.value.layout.report) {
      getVarDef(_lay.value.layout.report).then(res => {
        if (res.code === 200 && res.data.varJson) {
          initLayVarDef(_lay, res.data);
          if (_lay.value.layout.sort === "2" && _lay.value.sortRule.length > 0) {
            handleRequestImp();
          } else {
            //触发表格页面
            dataSortFilter(_dataObj, _lay, _tParObj);
            refTableComp.value.handleClickToolbar({ fCode: "$layout" });
          }
        } else {
          dataSortFilter(_dataObj, _lay, _tParObj);
        }
      });
    } else {
      dataSortFilter(_dataObj, _lay, _tParObj);
    }
  };

  //回调数据查询 $request 分页和排序触发请求数据
  const handleRequestImp = () => {
    let obj = {
      sort: _lay.value.layout.sort === "2" ? _lay.value.sortRule : []
    };
    //回调父级
    emit("handleRequest", obj);
  };

  //按钮回调事件
  const handleEventImp = obj => {
    emit("handleEvent", obj);
  };

  //行回调事件
  const handleRowEventImp = obj => {
    emit("handleRowEvent", obj);
  };

  //点击工具栏按钮
  const handleClickToolbar = obj => {
    switch (obj.fCode) {
      case "$filter": //过滤事件
        dataSortFilter(_dataObj, _lay, _tParObj);
        break;
      case "$layout":
        if (_lay.value.layout.sort === "2") {
          handleRequestImp();
        } else {
          dataSortFilter(_dataObj, _lay, _tParObj);
        }
        break;
      default:
        if (!obj.fCode.startsWith("$") || obj.fCode === "$upload") {
          handleEventImp(obj);
        }
        break;
    }
    //触发表格页面
    refTableComp.value.handleClickToolbar(obj);
  };

  //排序规则发生变化
  watch(
    () => props.sortRule,
    () => {
      setTimeout(() => {
        dataSortFilter(_dataObj, _lay, _tParObj);
      }, 0);
    }
  );

  //定义父组件可用方法
  const getRef = () => {
    return refTableComp.value.getRef();
  };

  //关闭详情
  const closeView = () => {
    refTableComp.value.closeView();
  };

  //定义父组件可用方法
  defineExpose({
    getRef,
    closeView
  });
</script>
