import router from '@/router'
import store from '@/store'
import {
	ElMessage
} from 'element-plus'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {
	getToken
} from './authToken'

NProgress.configure({
	showSpinner: false
})

const authList = ['/platform/manage']

router.beforeEach((to, from, next) => {
	NProgress.start()
	if (getToken()) {
		/* has token*/
		if (to.path === '/login') {
			next({
				path: '/platform/home'
			})
			NProgress.done()
		} else {
			if (store.state.frmUser.userData.custId == undefined) {
				// 判断当前用户是否已拉取完user_info信息
				store.dispatch('GetUserInfo').then(() => {
					next({
						...to,
						replace: true
					})
				})
					.catch(err => {
						store.dispatch('FedLogOut').then(() => {
							ElMessage.error(err)
							next({
								path: '/'
							})
						})
					})
			} else {
				next()
			}
		}
		// 员工号不允许访问管理中心
		if (to.path === '/platform/manage') {
			if (store.state.frmUser.userData.role == '1') {
				next({
					path: '/platform/home'
				})
			}
		}
	} else {
		// 没有token
		if (authList.indexOf(to.path) !== -1) { // 在强制授权名单，重定向到登录页
			next(`/login`)
			NProgress.done()
		} else {
			// 否则，直接进入
			next()
		}
	}
})

router.afterEach(() => {
	NProgress.done()
})
