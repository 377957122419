<script>
export default {
  data() {
    return {
      targets: [],
      isHover: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    sn: {
      type: String,
      default: "1",
    },
    codeData: {
      type: Object,
      default: () => ({}),
    },
    curCodeIndex: {
      type: String,
      default: "",
    },
  },
  computed: {
    codeIndex() {
      return "code" + this.sn;
    },
    anchorId() {
      const params = location.pathname.split("/");
      return params[params.length - 1] ?? "";
    },
  },
  methods: {
    /*
     * 划过和移出事件*/
    addHover(e) {
      e.target.classList.add("hover");
      e.target.firstChild.classList.add("hovering");
      e.target.lastChild.style.display = "";
      // this.isHover = true;
    },
    removeHover(e) {
      e.target.classList.remove("hover");
      e.target.firstChild.classList.remove("hovering");
      e.target.lastChild.style.display = "none";
      // this.isHover = false;
    },
    /*
     * 显示隐藏代码
     * */
    showCode(id) {
      if (this.targets.includes(id)) {
        console.log("this.targets.includes(id)");
        const index = this.targets.findIndex((item) => item == id);
        this.targets.splice(index, 1);
      } else {
        this.targets.push(id);
      }
    },
  },
};
</script>

<template>
  <div class="g-temp">
    <div class="level_title1" :id="anchorId + '_id' + sn">
      {{ sn + "." + title }}
    </div>
    <div class="demo-block">
      <div class="source pd24">
        <div class="app-container" style="display: block">
          <slot name="componentCode"></slot>
        </div>
      </div>
      <div class="m-code" v-show="targets.includes(codeIndex)">
        <slot name="code"></slot>
      </div>
      <div
        class="demo-block-control"
        @mouseenter.stop="addHover($event)"
        @mouseleave="removeHover($event)"
      >
        <el-icon v-if="targets.includes(codeIndex)" style="margin-top: 10px"><CaretTop /></el-icon>
        <el-icon v-else style="margin-top: 10px"><CaretBottom /></el-icon>
        <span
          :style="{ display: isHover ? '' : 'none' }"
          @click="showCode(codeIndex)"
          >{{ targets.includes(codeIndex) ? "隐藏代码" : "显示代码" }}</span
        >
      </div>
    </div>
    <h3 class="m-sn" v-if="codeData.labelTitle">
      {{ sn + ".1" + codeData.labelTitle }}属性
    </h3>
    <el-table
      v-if="codeData.labelData"
      :data="codeData.labelData"
      style="width: 100%"
    >
      <el-table-column prop="key" label="参数" width="180"> </el-table-column>
      <el-table-column prop="type" label="类型" width="180"> </el-table-column>
      <el-table-column prop="ps" label="说明">
        <template v-slot="scope">
          <div v-html="scope.row.ps"></div>
        </template>
      </el-table-column>
    </el-table>
    <h3 class="m-sn" v-if="codeData.fnTitle">
      {{ sn + (codeData.labelTitle ? ".2" : ".1") + codeData.fnTitle }}方法
    </h3>
    <el-table
      v-if="codeData.fnData"
      :data="codeData.fnData"
      style="width: 100%"
    >
      <el-table-column prop="key" label="方法名称" width="180">
      </el-table-column>
      <el-table-column prop="type" label="参数" width="180"> </el-table-column>
      <el-table-column prop="ps" label="说明">
        <template v-slot="scope">
          <div v-html="scope.row.ps"></div>
        </template>
      </el-table-column>
    </el-table>
    <h3 class="m-sn" v-if="codeData.directiveData">{{ sn + ".1" }}指令介绍</h3>
    <el-table
      v-if="codeData.directiveData"
      :data="codeData.directiveData"
      style="width: 100%"
    >
      <el-table-column prop="key" label="名称" width="180"> </el-table-column>
      <el-table-column prop="type" label="参数" width="180"> </el-table-column>
      <el-table-column prop="ps" label="说明">
        <template v-slot="scope">
          <div v-html="scope.row.ps"></div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style scoped lang="scss"></style>
