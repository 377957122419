<template>
	<div class="footer">
		<div class="row-box">
			<div class="footer-logo">
				<div class="footer-logo-img">
					<img src="../assets/img/logo/logo1.png" alt="">
				</div>
				<div class="footer-logo-title">
					为企业提供更高效便捷的信息化解决方案
				</div>
				<div class="footer-logo-con">
				</div>
				<div class="footer-logo-copyright">
					版权所有@河北尚智得信息技术有限公司
				</div>
				<div class="footer-con-address" style="margin-top: 10px;">
					<a href="https://beian.miit.gov.cn" target="_blank"
						style="text-decoration: none;color: #FFFFFF;">冀ICP备18034898号-1</a>
				</div>
			</div>
			<div class="footer-con">
				<div class="footer-con-address">
					地址：河北省石家庄市裕华区翟营南大街财库国际2505
				</div>
				<!-- <div class="footer-con-address">
					电话：0311-89892288
				</div> -->
				<!-- <div class="footer-con-address">
					传真：0311-89892288
				</div> -->
				<div class="footer-con-address">
					邮箱：marketing@szdsoft.cn
				</div>
				<div class="footer-con-address" style="display: flex;align-items: center;">
					<img src="../assets/img/logo/police.png" alt=""><span style="padding-left: 5px;"><a
							style="color: #FFFFFF;text-decoration: none;"
							href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13019902000221"
							target="_blank">冀公网安备13019902000221号</a></span>
				</div>
			</div>
			<div class="footer-end">
				<div class="footer-end-img">
					<img src="../assets/img/pubuilc/2.jpg" alt="">
				</div>
				<div class="footer-end-con">
					微信公众号
				</div>
			</div>
		</div>
	</div>
</template>

<script>
</script>

<style scoped>
.footer {
	width: 100%;
	height: 220px;
	background: #141B1E;
	display: flex;
	justify-content: center;
}

.footer-logo {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;
	margin-top: -16px;
}

.footer-logo-img {
	height: 78px;
	font-size: 38px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
}

.footer-logo-img img {
	width: auto;
	max-width: 180px;
	height: 100%;
	padding: 18px 0;
}

.footer-logo-title {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
}

.footer-logo-con {
	margin-top: 14px;
	margin-bottom: 10px;
	width: 135px;
	height: 1px;
	background-color: #4D5256;
}

.footer-logo-copyright {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
}

.footer-con {
	width: 342px;
	padding-top: 48px;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
}

.footer-con-address {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
	padding-bottom: 12px;
}

.footer-end {
	width: 100%;
	height: 100%;
	display: flex;
	padding-top: 39px;
	flex-direction: column;
}

.footer-end-img {
	width: 95px;
	height: 95px;
}

.footer-end-img img {
	width: 100%;
	height: 100%;
}

.footer-end-con {
	width: 95px;
	padding: 17px 0 0 18px;
	font-size: 12px;
	font-family: PingFang-SC-Bold, PingFang-SC;
	font-weight: bold;
	color: #FFFFFF;
}
</style>
