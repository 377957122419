<template>
    <div class="com_padding">
    <el-table :data="tableObj.data" style="width: 100%" :size="tableObj.size?tableObj.size:'mini'" border header-row-class-name="table_header_class"
        :span-method="tableObj.spanMethod">
        <el-table-column v-for="item in tableObj.option" :prop="item.prop" :label="item.label" :width="item.width || 'auto'"
            :align="item.align || 'left'" :key="item.prop">
            <template v-slot="scope">
                <div v-if="item.render" v-html="item.render(scope.row)"></div>
                <div v-else>{{ scope.row[item.prop] }}</div>
            </template>
        </el-table-column>
    </el-table>
</div>
</template>

<script>
export default {
    name: 'SzdWebsiteTable',
    props: {
        tableObj: {
            type: Object,
            default() {
                return {
                    // 数据 必填
                    data: [],
                    // 表格高度，选填
                    height: 'auto',
                    // 表格尺寸
                    size: 'mini',
                    // 表格展示项 必填
                    option: [
                        // prop 字段 必填    label 标题 必填    width 列宽 选填  align对齐方式 默认left  render自定义内容
                        { prop: 'name1', label: '名称1', width: '', align: 'left' },
                        { prop: 'name2', label: '名称2', width: '', align: 'left' },
                        { prop: 'name3', label: '名称2', width: '', align: 'left' },
                        {
                            prop: 'name3', label: '名称2', width: '', render(row) {
                                console.log(row)
                                return `<div class="color_blue">这是render自定义内容</div>`
                            }
                        },
                    ],
                    spanMethod() {
                        return [1, 1];
                    }
                }
            }
        }
    },
    data() {
        return {
            // 示例参数
            // tableObj: {
            //     data: [],
            //     height: 'auto',
            //     option: [
            //         { prop: 'date', label: '日期', width: '' },
            //         { prop: 'name', label: '姓名', width: '' },
            //         { prop: 'address', label: '地址', width: '' },
            //     ]
            // }
        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="scss">
.table_header_class th {
    background-color: #F3F5F9 !important;
}
</style>