import { ElMessage } from "element-plus";
import szdStoreLocal from "../store/storeLocal";

/**
 * 减少频繁调用缓存
 * */
const getSingleDbLang = (function () {
  let ret;
  return function () {
    return ret || (ret = szdStoreLocal.getLang());
  };
})();

/**
 * 语言转换
 * */
export function $t(val) {
  if (!val) return "";
  const dbLang = getSingleDbLang();
  return dbLang[val] ? dbLang[val] : val;
}

/**
 * 消息提示
 * @param {String} msgCode 消息类
 * @param {String} msgTypeNum 消息类型和消息号 消息类型枚举 E-错误 W-警告 S-成功 I-消息，消息号是3位数字
 * @param {Object} param 参数，{p1:替换消息第一个&,p2替换消息第二个&,pn替换消息第n个}，未替换的直接拼接到一块
 * @param {Boolean} retMsg 是否返回消息内容，否则提醒
 */
export function $m(msgCode, msgTypeNum, param = {}, retMsg = false) {
  if (!msgCode || !msgTypeNum) return "";
  const msgObj = szdStoreLocal.getMsg();
  let message = "";
  if (msgObj[msgCode] && msgObj[msgTypeNum.slice(1)]) {
    message = msgObj[msgTypeNum.slice(1)];
  }
  // 参数情况
  let keys = param ? Object.keys(param) : [];
  if (keys.length > 0) {
    let mArr = message.split("&");
    message = "";
    let pos = 0;
    for (let i = 0; i < mArr.length; i++) {
      pos = i + 1;
      message = message + mArr[i] + param["p" + pos] ? param["p" + pos] : "";
    }
    for (let j = 0; j < keys.length; j++) {
      if (Number(keys[j].slice(1)) > pos && param[keys[j]]) message = message + param[keys[j]];
    }
  }
  if (retMsg) return message;
  if (msgTypeNum[0] === "S") {
    ElMessage.success(message);
  } else if (msgTypeNum[0] === "W") {
    ElMessage.warning(message);
  } else if (msgTypeNum[0] === "E") {
    ElMessage.error(message);
  } else {
    ElMessage.info(message);
  }
}

/**
 * 提示成功
 */
export function $mS(message) {
  ElMessage.success($t(message));
}

/**
 * 提示警告
 */
export function $mW(message) {
  ElMessage.warning($t(message));
}

/**
 * 提示错误
 */
export function $mE(message) {
  ElMessage.error($t(message));
}

/**
 * 提示消息
 */
export function $mI(message) {
  ElMessage.info($t(message));
}
